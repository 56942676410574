import { faExternalLinkAlt, faToolbox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql } from 'gatsby';
import * as PropTypes from 'prop-types';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Img from 'gatsby-image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BackgroundImage from 'gatsby-background-image';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import LocalizedLink from '../../components/LocalizedLink';
// import lseLogo from '../../../content/assets/lseLogo.svg';

export default function Certifications({ location, data, pathContext: { locale, pageSlugs } }) {
  const { markdownFileText: { frontmatter } } = data;

  return (
    <Layout location={location} locale={locale} pageSlugs={pageSlugs}>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
        language={locale}
      />
      <BackgroundImage
        tag="div"
        className="d-table w-100 h-175-px"
        fluid={data.datArtAboutUsHeaderImage.childImageSharp.fluid}
      >
        <div className="d-table-cell align-middle text-center">
          <h1
            className="d-inline-block title-font-size text-white text-center bg-darken rounded p-4
            mx-4"
          >
            {frontmatter.mainTitle}
          </h1>
        </div>
      </BackgroundImage>
      {/* <Container className="p-4 min-vh-100"> */}
      <Container className="p-4">
        <h2 id="sap" className="pl-2 left-border">{frontmatter.sapPartnerTitle}</h2>
        <Img 
          fluid={data.sapPartnerLogo.childImageSharp.fluid} 
          className="h-auto labels-sap-logo-w my-4 mx-auto"
        /> 
        <p>{frontmatter.sapPartnerP1Text}</p>
        <p>{frontmatter.sapPartnerP2Text}</p>
        <p>{frontmatter.sapPartnerP3Text}</p>
        <p>{frontmatter.sapPartnerP4Text}</p>
        <h2 id="lse" className="mt-5 pl-2 left-border">{frontmatter.lseCertificationTitle}</h2>
        {/* <div className="text-center"> */}
        {/*  <img */}
        {/*    src={lseLogo} */}
        {/*    alt="LSE Logo" */}
        {/*    className="h-auto my-4 labels-lse-logo-w" */}
        {/*  /> */}
        {/* </div> */}
        <Img
          fluid={data.vdLogo.childImageSharp.fluid}
          className="width-7 h-auto my-4 mx-auto"
        />
        <p>{frontmatter.lseCertificationText}</p>
      </Container>
    </Layout>
  );
}

Certifications.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  pathContext: PropTypes.object.isRequired,
};

export const query = graphql`  
    fragment pagesHeaderFluidImage on File {
        childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
  
    fragment certificationsLogoFluidImage on File {
        childImageSharp {
            fluid(quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
            }
        }
    }

    query($locale: String!) {
        markdownFileText: markdownRemark(frontmatter: {lang: {eq: $locale}} fields: {fileName:
        {eq: "about-us-certifications"}}) {
            frontmatter {
                title
                description

                mainTitle

                sapPartnerTitle
                sapPartnerP1Text
                sapPartnerP2Text
                sapPartnerP3Text
                sapPartnerP4Text
                
                lseCertificationTitle
                lseCertificationText
            }
        }
        
        datArtAboutUsHeaderImage: file(relativePath: {regex: "/datArtAboutUsHeaderImage/"}) {
            ...pagesHeaderFluidImage
        }

        vdLogo: file(relativePath: {regex: "/vdLogo/"}) {
            ...certificationsLogoFluidImage
        }

        sapPartnerLogo: file(relativePath: {regex: "/sapPartnerLogo/"}) {
            ...certificationsLogoFluidImage
        }
    }
`;
